<template>
    <!--    <el-table>-->
    <table style="width:100%">
        <tr>
            <td  style="width:20%"><span>{{ $t('Название организации') }}</span></td>
            <td style="width:80%">
                <el-input style="width:80%">
                </el-input>
            </td>
        </tr>
        <tr>
            <td  style="width:20%"><span>{{ $t('Шаблон для формирования текста запроса') }}</span></td>
            <td style="width:80%">
                <textarea rows="5" style="width:80%">
                </textarea>
            </td>
        </tr>
        <tr>
            <td style="width:20%"><span>{{ $t('Шаблон для формирования текста ответа') }}</span></td>
            <td style="width:80%">
                <textarea rows="5" style="width:80%">
                </textarea>
            </td>
        </tr>
        <tr>
            <td style="width:20%"><span>{{ $t('Ответ по умолчанию') }}</span></td>
            <td style="width:80%">
                <textarea rows="5" style="width:80%">
                </textarea>
            </td>
        </tr>
    </table>
    <el-button>{{ $t('Сохранить') }}</el-button>

    <!--    </el-table>-->

</template>

<script>
    export default {
        name: 'SystemSettings'
    }
</script>

<style scoped>


</style>