<template>
    <div class="content">
        <section class="cropper-area">
            <div class="img-cropper">
                <vue-cropper
                        ref="cropper"
                        :src="imageSrc"
                        preview=".preview"
                />
            </div>
            <div class="actions">
                <el-row  style="text-align: start">
                    <el-button type="primary"
                               @click="zoom(0.2)"
                               style="margin-right: 4px">
                        {{ $t('Приблизить') }}
                    </el-button>
                    <el-button type="primary"
                               @click="zoom(-0.2)"
                               style="margin-right: 4px">
                        {{ $t('Отдалить') }}
                    </el-button>
                    <el-button
                            @click="rotate(-90)"
                            type="primary">
                        {{ $t('Повернуть') }}
                    </el-button>
                </el-row>
                <el-row style="text-align: end">
                    <el-button type="danger"
                               @click.prevent="reset"
                               style="margin-right: 16px">
                        {{ $t('Сбросить') }}
                    </el-button>
                    <el-button
                            @click.prevent="cropImage"
                            type="success">
                        {{ $t('Сохранить') }}
                    </el-button>
                </el-row>
            </div>

        </section>
    </div>
</template>

<script>
    import VueCropper from 'vue-cropperjs';

    export default {
        name: 'Profile',
        components: {
            VueCropper
        },
        data() {
            return {
                data: null,
                imageCrop: null
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        props: {
            imageSrc: String
        },
        mounted() {
            this.setImage()
            this.setImage()
        },
        methods: {
            cropImage() {
                // get image data for post processing, e.g. upload or setting image src
                this.imageCrop = this.$refs.cropper.getCroppedCanvas().toDataURL();
                this.$emit('crop-image', this.imageCrop)
            },
            reset() {
                this.$refs.cropper.reset();
            },
            rotate(deg) {
                this.$refs.cropper.rotate(deg);
            },
            setImage() {
                this.$refs.cropper.replace(this.imageSrc);
            },
            zoom(percent) {
                this.$refs.cropper.relativeZoom(percent);
            }
        },
    };
</script>

<style scoped>

    .content {
        display: flex;
        justify-content: space-between;
    }
    .actions {
        margin-top: 1rem;
    }
    .actions a {
        display: inline-block;
        padding: 5px 15px;
        background: #0062CC;
        color: white;
        text-decoration: none;
        border-radius: 3px;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
    textarea {
        width: 100%;
        height: 100px;
    }

    .preview-area p {
        font-size: 1.25rem;
        margin: 0;
        margin-bottom: 1rem;
    }
    .preview-area p:last-of-type {
        margin-top: 1rem;
    }
    .cropped-image img {
        max-width: 100%;
    }

</style>
