<template>
  <div>
    <el-dialog
        @closed="loading = false"
        v-model="cropActive">
      <crop-image v-if="cropActive"
                  @crop-image="setCroppedImage"
                  :imageSrc="imageSrc">

      </crop-image>

    </el-dialog>

    <div v-loading.fullscreen.lock="loading">
      <el-row style="padding: 16px; width:100%">
        <el-col :span="6">
          <el-row>
            <el-card style="width:100%">
              <a style="margin-left: 16px; align-items: center; display:flex">
                <div @mouseover="showReloadAvatar = true" @mouseleave="showReloadAvatar = false">
                  <a style="display: flex" @click="handleAvatarClick">
                    <el-avatar :class="!showReloadAvatar ? '' : 'overflowAvatar'" :src="cropImg"
                               :size="80" style="line-height: 90px;">
                    </el-avatar>
                    <div v-if="showReloadAvatar" style="position: absolute; top: 44px; left: 60px">
                      <i class="fas fa-redo-alt fa-2x" style="color: #1b1b1b;"></i>
                    </div>
                  </a>
                  <input hidden type="file" @change="uploadImage" id="imageAvatar"
                         accept="image/png,image/x-png,image/gif,image/jpeg,image/jpg"/>
                </div>
                <div style="margin-left: 16px;">
                  <el-row style="text-align: start">
                    <div class="title" style="font-size: 20px">{{ currentUser.username }}
                    </div>
                  </el-row>
                  <el-row style="text-align: start">
<!--                    <span :class="'flag-icon flag-icon-' + currentUser.country['0'].toLowerCase()"></span>-->
                  </el-row>
                </div>

              </a>
            </el-card>
          </el-row>

          <el-row class="mt-3">
            <el-card style="width:100%">
              <el-row class="tac" style="text-align: left">
                <el-col>
                  <el-menu
                      default-active="2"
                      class="el-menu-vertical"
                      @select="profileMenuSelect">
                    <el-menu-item index="1">
                      <i class="el-icon-info"></i>
                      <span>{{ $t('Информация') }}</span>
                    </el-menu-item>
                    <el-menu-item index="2">
                      <i class="el-icon-document"></i>
                      <span>{{ $t('Мои кейсы') }}</span>
                    </el-menu-item>
                    <el-menu-item  @click="openLink">
                      <i class="el-icon-notebook-1"></i>
                      <span>{{ $t('Инструкция') }}</span>
                    </el-menu-item>
                    <el-menu-item index="3" v-if="showAdminBoard">
                      <i class="el-icon-setting"></i>
                      <span>{{ $t('Управление пользователями') }}</span>
                    </el-menu-item>
                    <el-menu-item index="4" v-if="showAdminBoard">
                      <i class="el-icon-setting"></i>
                      <span>{{ $t('Настройка системы') }}</span>
                    </el-menu-item>
                  </el-menu>
                </el-col>
              </el-row>
            </el-card>
          </el-row>

        </el-col>
        <el-col :span="18" style="padding-left: 16px">
          <el-card style="width:100%">
            <template #header>
              <div v-if="menuIndex === '1'" style="text-align: left">
                <span>{{ $t('Информация о пользователе') }}</span>
                <br>
                <span style="color: blue">{{ updateMessage }}</span>
              </div>
              <div v-else-if="menuIndex === '2'" style="text-align: left">{{ $t('Мои кейсы') }}</div>
              <div v-else-if="menuIndex === '3'" style="text-align: left">{{ $t('Настройки пользователей') }}</div>
              <div v-else-if="menuIndex === '4'" style="text-align: left">{{ $t('Настройки системы') }}</div>
            </template>
            <div v-if="menuIndex === '1'">
              <el-row type="flex" align="middle">
                <el-col :span="12" style="text-align: start">
                  <a>
                    <strong>ID: </strong>
                    <a>
                      {{ currentUser.id }}
                    </a>
                  </a>
                </el-col>
              </el-row>
              <hr/>
              <el-row type="flex" align="middle">
                <el-col :span="12" style="text-align: start">
                  <a>
                    <strong>{{ $t('Имя') }}: </strong>
                    <a>
                      {{ currentUser.username }}
                    </a>
                  </a>
                </el-col>

                <el-col :span="12" style="text-align: end">
                  <div v-if="changeName">
                    <el-input
                        v-model="newName"
                        type="text"
                        style="margin-right: 8px; width:75%"
                        autocomplete="on"
                    >
                    </el-input>
                    <el-button type="success" @click="changeNameHandle">
                      {{ $t('Сохранить') }}
                    </el-button>
                  </div>
                  <el-button v-else @click="changeName = true">
                    {{ $t('Изменить') }}
                  </el-button>
                </el-col>
              </el-row>
              <hr/>

              <el-row type="flex" align="middle">
                <el-col :span="12" style="text-align: start">
                  <a>
                    <strong>{{ $t('Изменение пароля') }} </strong>
                  </a>
                </el-col>

                <el-col :span="12" style="text-align: end">
                  <div v-if="changePassword">

                    <el-row>
                      <el-col>
                        <span>{{ $t('Введите новый пароль') }}</span>
                      </el-col>
                      <el-col>
                        <el-input
                            v-model="newPassword"
                            type="password"
                            style="margin-right: 8px; width:75%"
                        >
                        </el-input>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col>
                        <span>{{ $t('Повторите новый пароль') }}</span>
                      </el-col>
                      <el-col>
                        <el-input
                            v-model="newPasswordCheck"
                            type="password"
                            style="margin-right: 8px; width:75%"
                        >
                        </el-input>
                      </el-col>
                    </el-row>
                    <el-button type="success" @click="changePasswordHandle">
                      {{ $t('Сохранить') }}
                    </el-button>
                  </div>
                  <el-button v-else @click="changePassword = true">
                    {{ $t('Изменить') }}
                  </el-button>
                </el-col>
              </el-row>
              <hr/>
              <el-row type="flex" align="middle">
                <el-col :span="12" style="text-align: start">
                  <a>
                    <strong>Email: </strong>
                    <a>
                      {{ currentUser.email }}
                    </a>
                  </a>
                </el-col>
                <el-col :span="12" style="text-align: end">
                  <div v-if="changeEmail">
                    <el-input v-model="newEmail" type="email" style="margin-right: 8px; width:75%">
                      {{ currentUser.email }}
                    </el-input>
                    <el-button type="success" @click="changeEmailHandle">
                      {{ $t('Сохранить') }}
                    </el-button>
                  </div>
                  <el-button v-else @click="changeEmail = true">
                    {{ $t('Изменить') }}
                  </el-button>
                </el-col>
              </el-row>
              <hr/>
              <el-row type="flex" align="middle">
                <el-col :span="12" style="text-align: start">
                  <a>
                    <strong>{{ $t('Роли') }}: </strong>
                    <a>
                      {{ currentUser.roles.join(', ') }}
                    </a>
                  </a>
                </el-col>
              </el-row>
              <hr/>
              <el-row type="flex" align="middle">
                <el-col :span="12" style="text-align: start">
                  <a>
                    <strong>{{ $t('Страна') }}: </strong>
                    <a>
                      {{ $t(currentUser.country['4']) }}
                      <span style="margin-left: 4px"
                            :class="'flag-icon flag-icon-' + currentUser.country['0'].toLowerCase()"></span>
                    </a>
                  </a>
                </el-col>
                <el-col :span="12" style="text-align: end">
                  <div v-if="changeCountry">
                    <el-select v-model="countrySelect"
                               :no-match-text="$t('Ничего не найдено')"
                               value-key="2"
                               filterable
                               style="margin-right: 8px"
                               :placeholder="$t('Выберите страну')">
                      <el-option
                          v-for="item in countriesList"
                          :key="item['2']"
                          :label="$t(item['4'])"
                          :value="item">
                      </el-option>
                    </el-select>
                    <el-button type="success" @click="changeCountryHandle">
                      {{ $t('Сохранить') }}
                    </el-button>
                  </div>
                  <el-button v-else @click="changeCountry = true">
                    {{ $t('Изменить') }}
                  </el-button>
                </el-col>
              </el-row>
              <hr/>
              <el-row type="flex" align="middle">
                <el-col :span="12" style="text-align: start">
                  <a>
                    <strong>{{ $t('Версия') }}: </strong>
                    <a>
                      20240915
                    </a>
                  </a>
                </el-col>
              </el-row>
              <hr/>

            </div>
            <el-row v-else-if="menuIndex === '2'">
              <el-table
                  :data="userCases"
                  style="width: 100%"
                  :height="height"
                  @expand-change="expandChange"
              >
                <el-table-column type="expand">
                  <template #default="props">
                    <previewCase v-if="props.row.expand" :case="props.row"/>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    :label="$t('Название')">
                </el-table-column>
                <el-table-column
                    prop="stringDate"
                    :label="$t('Дата создания')">
                </el-table-column>
                <el-table-column
                    label=""
                    width="200">
                  <template #default="props">
                    <el-button type="primary"
                               v-if="props.row.caseStatus && !props.row.IsStarted && !props.row.finished "
                               size="small"
                               @click="startCaseSession(props.row)">
                      <i class="fas fa-play fa-lg"></i>
                    </el-button>
                    <el-button type="primary"
                               v-else-if="props.row.caseStatus && props.row.IsStarted"
                               size="small"
                               @click="continueCaseSession(props.row)">
                      <i class="fa fa-hourglass-half fa-lg" aria-hidden="true"></i>
                    </el-button>
                    <el-button type="primary" v-else
                               size="small" disabled style="background-color: #39b54a">
                      <i class="fa fa-check fa-lg" aria-hidden="true"></i>
                    </el-button>
                  </template>
                </el-table-column>
<!--                <el-table-column-->
<!--                    prop="chatLink"-->
<!--                    label="Ссылка на чат">-->
<!--                  <template #default="props"><a :href="'' + props.row.chatLink">Перейти в чат</a>-->
<!--                  </template>-->
<!--                </el-table-column>-->
              </el-table>
            </el-row>
            <el-row v-else-if="menuIndex === '3'">
              <UserSettings />
            </el-row>
            <el-row v-else-if="menuIndex === '4'">
              <SystemSettings />
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import CropImage from './CropImage';
import UserSettings from './UserSettings'
import ProfileController from "../../controllers/profile.controller";
import previewCase from "../Cases/previewCase";
import SystemSettings from '../SystemSettings'
import CaseController from "../../controllers/case.controller";
import CaseSessionController from "../../controllers/caseSession.controller"
import bcrypt from "bcryptjs";
import config from "../../../config";

export default {
  name: 'Profile',
  components: {
    CropImage,
    UserSettings,
    previewCase,
    SystemSettings
  },
  data() {
    return {
      menuIndex: '2',
      showReloadAvatar: false,
      userCases: [],
      imageSrc: '',
      country: {name: 'Россия', iso: 'ru'},
      countrySelect: '',
      newEmail: '',
      newPassword: '',
      newPasswordCheck: '',
      newName: '',
      cropActive: false,
      cropImg: null,
      profileController: null,
      caseController: null,
      countriesList: null,
      changeCountry: false,
      changeEmail: false,
      changePassword: false,
      changeName: false,
      loading: true,
      caseSessionController: null,
      caseSessions: null,
      logInterval: null,
      updateMessage: ''
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }
      return false;
      // return true
    },

  },
  mounted() {
    const vm = this
    this.profileController = new ProfileController()
    this.caseController = new CaseController()
    this.caseSessionController = new CaseSessionController()
    // load countries to select
    this.profileController.getCountries().then((response) => {
      vm.countriesList = response.data.data
    })
    //
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    // load cases
    this.caseController.caseByUserId(this.currentUser.id).then((data) => {
      vm.userCases = data ? data.map(t => {
        if (!t.date) {
          t.stringDate = '';
        } else {
          const date = new Date(t.date);
          const locale = 'ru-RU';
          t.stringDate = !isNaN(date) ? `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale)}` : '';
        }
        return t;
      }) : [];

      vm.caseSessionController.caseSessionByUserId(vm.currentUser.id).then((data) => {
        for (let session of data) {
          for (let Case of vm.userCases) {
            if (Case.caseId === session.caseId) {
              Case.IsStarted = true
              Case.finished = session.finished
            }
          }
        }
      })
    })
    // load avatar
    if (this.currentUser.picture) {
      this.setCroppedImage(this.currentUser.picture)
    }
    this.newName = this.currentUser.username;
    this.newEmail = this.currentUser.email;
    this.loading = false;

  },
  methods: {
    openLink() {
      window.open(config.instruction_link, '_blank')
    },
    expandChange(row) {
      row.expand = !row.expand
    },
    messageVanish() {
      if (this.logInterval !== null) clearInterval(this.logInterval)

      this.logInterval = setInterval(() => {
        this.updateMessage = ''
      }, 10000);
    },

    changePasswordHandle() {
      if (this.newPassword == this.newPasswordCheck) {
        const vm = this
        this.changePassword = false
        this.profileController.updateInfo('password', bcrypt.hashSync(this.newPassword, 8), this.currentUser.id)

        this.profileController.setUserStore(this.currentUser)

        if (this.logInterval !== null) clearInterval(this.logInterval)

        this.logInterval = setInterval(() => {
          localStorage.setItem('user', JSON.stringify(vm.profileController.getUserStore()))
          // this.messageVanish()
        }, 1000);

        this.updateMessage = this.$t('Пароль изменен')
      } else {
        this.updateMessage = this.$t('Пароли не совпадают!')
        // this.messageVanish()
      }
    },
    changeNameHandle() {
      this.changeName = false
      const vm = this
      this.profileController.updateInfo('username', this.newName, this.currentUser.id)

      this.profileController.setUserStore(this.currentUser)


      if (this.logInterval !== null) clearInterval(this.logInterval)

      this.updateMessage = this.$t('Имя изменено')

      this.logInterval = setInterval(() => {
        this.currentUser.username = this.newName
        localStorage.setItem('user', JSON.stringify(vm.profileController.getUserStore()))
        // this.messageVanish()
      }, 1000);

    },
    changeEmailHandle() {
      this.changeEmail = false
      const vm = this
      this.profileController.updateInfo('email', this.newEmail, this.currentUser.id)

      this.profileController.setUserStore(this.currentUser)

      if (this.logInterval !== null) clearInterval(this.logInterval)

      this.logInterval = setInterval(() => {
        this.currentUser.email = this.newEmail
        localStorage.setItem('user', JSON.stringify(vm.profileController.getUserStore()))
        // this.messageVanish()
      }, 1000);

      this.updateMessage = this.$t('Email изменен')
    },
    changeCountryHandle() {
      const vm = this
      this.changeCountry = false
      // this.country.name = this.countrySelect['4']
      // this.country.iso = this.countrySelect['0'].toLowerCase()
      this.profileController.updateInfo('country', this.countrySelect, this.currentUser.id)

      this.profileController.setUserStore(this.currentUser)


      if (this.logInterval !== null) clearInterval(this.logInterval)

      this.logInterval = setInterval(() => {
        this.currentUser.country = this.countrySelect
        localStorage.setItem('user', JSON.stringify(vm.profileController.getUserStore()))
        // this.messageVanish()
      }, 1000);

      this.updateMessage = this.$t('Страна изменена')
    },
    setCroppedImage(image) {
      const vm = this
      vm.cropActive = false
      vm.cropImg = image
      vm.imageSrc = null
      this.loading = false
    },
    uploadImage() {
      this.loading = true
      const vm = this
      let file = document.getElementById('imageAvatar').files[0]
      let fr = new FileReader();
      fr.onload = (function () {
        return function (e) {
          vm.imageSrc = e.target.result
          vm.cropActive = true
          document.getElementById('imageAvatar').value = ""
        }
      })();
      fr.readAsDataURL(file);

      fr.onloadend = function () {
        let fileBase64 = fr.result
        vm.profileController.updateInfo('picture', fileBase64, vm.currentUser.id)
        if (vm.logInterval !== null) clearInterval(vm.logInterval)

        vm.logInterval = setInterval(() => {
          vm.currentUser.picture = fileBase64
          localStorage.setItem('user', JSON.stringify(vm.profileController.getUserStore()))
        }, 1000);
        this.profileController.setUserStore(this.currentUser)
      }

    },
    handleAvatarClick() {
      document.getElementById('imageAvatar').click()
    },
    profileMenuSelect(index) {
      this.menuIndex = index
    },
    startCaseSession(row_case) {
      let allsessinos = this.caseSessionController.getAllSessionsStore()
      const vm = this
      let sessioneixst = false
      for (let i = 0; i < allsessinos.length; i++) {
        if (allsessinos[i].caseId === row_case.caseId) {
          sessioneixst = true
        }
      }
      if (!sessioneixst) {
        this.caseSessionController.createCaseSession(this.currentUser.id, row_case).then(() => {
          row_case.isSession = true;
          row_case.IsStarted = true
          vm.$emit('start-session', {status: true, caseId: row_case.caseId})
        })
      }
    },
    continueCaseSession(row_case) {
      this.$emit('start-session', {status: true, caseId: row_case.caseId})
      //todo получать схемы конкретной сессии
    }
  },
};
</script>

<style scoped>

.overflowAvatar {
  opacity: 0.3;
}


</style>
